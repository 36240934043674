import applyInstructions, {
  getTouchedFromInstruction,
} from './applyInstructions'

import moveElement from './moveElement'
import moveScreen from './moveScreen'
import resizeElement from './resizeElement'
import resizeScreen from './resizeScreen'
import enableDeviceSpecificLayout from './enableDeviceSpecificLayout'
import disableDeviceSpecificLayout from './disableDeviceSpecificLayout'
import toggleDeviceVisibility from './toggleDeviceVisibility'
import groupObjects from './groupObjects'
import groupObjectsToList from './groupObjectsToList'
import ungroupObjects from './ungroupObjects'
import toggleFixedPosition from './toggleFixedPosition'
import resetSharedLayout from './resetSharedLayout'
import updateCustomListDeviceColumns from './updateCustomListDeviceColumns'
import enableDeviceSpecificCustomListColumns from './enableDeviceSpecificCustomListColumns'
import disableDeviceSpecificCustomListColumns from './disableDeviceSpecificCustomListColumns'
import updateElementWidthConstraints from './updateElementWidthConstraints'
import updateElement from './updateElement'
import updateElementMargins from './updateElementMargins'
import reorderSection from './reorderSection'
import snapToBottom from './snapToBottom'
import fitScreenHeightToComponents from './fitScreenHeightToComponents'

export {
  applyInstructions,
  getTouchedFromInstruction,
  // Instruction Types
  moveElement,
  moveScreen,
  resizeElement,
  resizeScreen,
  enableDeviceSpecificLayout,
  disableDeviceSpecificLayout,
  toggleDeviceVisibility,
  groupObjects,
  groupObjectsToList,
  ungroupObjects,
  toggleFixedPosition,
  resetSharedLayout,
  updateCustomListDeviceColumns,
  enableDeviceSpecificCustomListColumns,
  disableDeviceSpecificCustomListColumns,
  updateElementWidthConstraints,
  updateElement,
  updateElementMargins,
  reorderSection,
  snapToBottom,
  fitScreenHeightToComponents,
}
