import applyLayoutInstruction, {
  getTouchedFromInstruction,
} from './applyLayoutInstruction'
import alignToScreenHorizontalCenter from './alignToScreenHorizontalCenter'
import alignToScreenVerticalCenter from './alignToScreenVerticalCenter'
import alignToSelectionBottom from './alignToSelectionBottom'
import alignToSelectionHorizontalCenter from './alignToSelectionHorizontalCenter'
import alignToSelectionLeft from './alignToSelectionLeft'
import alignToSelectionRight from './alignToSelectionRight'
import alignToSelectionTop from './alignToSelectionTop'
import alignToSelectionVerticalCenter from './alignToSelectionVerticalCenter'
import distributeHorizontal from './distributeHorizontal'
import distributeVertical from './distributeVertical'

export {
  applyLayoutInstruction,
  getTouchedFromInstruction,
  // instruction types
  alignToScreenHorizontalCenter,
  alignToScreenVerticalCenter,
  alignToSelectionBottom,
  alignToSelectionHorizontalCenter,
  alignToSelectionLeft,
  alignToSelectionRight,
  alignToSelectionTop,
  alignToSelectionVerticalCenter,
  distributeHorizontal,
  distributeVertical,
}
