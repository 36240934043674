export * from './plans'

// Selectors for data binding
export const CURRENT_USER_SELECTOR = 'CURRENT_USER_SELECTOR' as const

export const selectors = {
  CURRENT_USER: CURRENT_USER_SELECTOR,
  LIST_ITEM: 'LIST_ITEM_SELECTOR',
  ROUTE_PARAM: 'ROUTE_PARAM_SELECTOR',
  SELECT_VALUE: 'SELECT_VALUE_SELECTOR',
  CREATED_OBJECT: 'CREATED_OBJECT',
} as const

// Transition types
export const TRANSITION_PUSH = 'TRANSITION_PUSH' as const
export const TRANSITION_MODAL = 'TRANSITION_MODAL' as const
export const TRANSITION_NONE = 'TRANSITION_NONE' as const

export const transitions = {
  PUSH: TRANSITION_PUSH,
  NONE: TRANSITION_NONE,
  MODAL: TRANSITION_MODAL,

  // Slide - back view moves at same speed
  SLIDE_LEFT: 'TRANSITION_SLIDE_LEFT',
  SLIDE_RIGHT: 'TRANSITION_SLIDE_RIGHT',
  SLIDE_UP: 'TRANSITION_SLIDE_UP',
  SLIDE_DOWN: 'TRANSITION_SLIDE_DOWN',

  // Float - back view is stationary
  FLOAT_LEFT: 'TRANSITION_FLOAT_LEFT',
  FLOAT_RIGHT: 'TRANSITION_FLOAT_RIGHT',
  FLOAT_UP: 'TRANSITION_FLOAT_UP',
  FLOAT_DOWN: 'TRANSITION_FLOAT_DOWN',
} as const

export const TRANSITION_TYPE_OPTIONS = [
  { label: 'Push', value: transitions.PUSH, icon: 'push' },
  { label: 'Modal', value: transitions.MODAL, icon: 'modal' },
  { label: 'None', value: transitions.NONE, icon: 'none' },
  null,
  { label: 'Slide Left', value: transitions.SLIDE_LEFT, icon: 'slide-left' },
  { label: 'Slide Right', value: transitions.SLIDE_RIGHT, icon: 'slide-right' },
  { label: 'Slide Up', value: transitions.SLIDE_UP, icon: 'slide-up' },
  { label: 'Slide Down', value: transitions.SLIDE_DOWN, icon: 'slide-down' },
  null,
  { label: 'Float Left', value: transitions.FLOAT_LEFT, icon: 'float-left' },
  { label: 'Float Right', value: transitions.FLOAT_RIGHT, icon: 'float-right' },
  { label: 'Float Up', value: transitions.FLOAT_UP, icon: 'float-up' },
  { label: 'Float Down', value: transitions.FLOAT_DOWN, icon: 'float-down' },
] as const

// Object types
export const LABEL = 'label' as const
export const SECTION = 'section' as const
export const LAYOUT_SECTION = 'LAYOUT_SECTION' as const
export const ELLIPSE = 'ellipse' as const
export const SHAPE = 'shape' as const
export const WRAPPER = 'wrapper' as const
export const GROUP = 'group' as const
export const IMAGE = 'image' as const
export const VIDEO = 'video' as const
export const TABLE = 'table' as const
export const LIST = 'list' as const
export const CARD_VIEW = 'card-view' as const
export const INPUT = 'input' as const
export const IMAGE_UPLOAD = 'image-upload' as const
export const FILE_UPLOAD = 'file-upload' as const
export const DATE_PICKER = 'date-picker' as const
export const LOCATION_INPUT = 'location-input' as const
export const SELECT = 'select' as const
export const FORM = 'form' as const
export const ROW = 'row' as const
export const CHECKBOX = 'checkbox' as const
export const COLUMN = 'column' as const
export const ROW_SPACER = 'rowSpacer' as const
export const COLUMN_SPACER = 'columnSpacer' as const
export const WEB_VIEW = 'web-view' as const
export const COMPONENT = 'component' as const
export const COMPONENT_INSTANCE = 'componentInstance' as const
export const LIBRARY_COMPONENT = 'libraryComponent' as const
export const LINE = 'line' as const
// Only used in runner by responsive web apps and not present in the app body
export const LIST_ITEM = 'LIST_ITEM' as const

export const TYPE_OPTIONS = [
  { label: 'Text', value: LABEL },
  { label: 'Rectangle', value: SECTION },
  { label: 'Section', value: LAYOUT_SECTION },
  { label: 'Ellipse', value: ELLIPSE },
  { label: 'Shape', value: SHAPE },
  { label: 'Group', value: GROUP },
  { label: 'List', value: LIST },
  { label: 'Image', value: IMAGE },
  { label: 'Text Field', value: INPUT },
  { label: 'Image Picker', value: IMAGE_UPLOAD },
  { label: 'File Picker', value: FILE_UPLOAD },
  { label: 'Date Picker', value: DATE_PICKER },
  { label: 'Dropdown Menu', value: SELECT },
  { label: 'Web View', value: WEB_VIEW },
  { label: 'Line', value: LINE },
] as const

// Generic constants
export const ACTION = 'action' as const
// NAVIGATION as const
export const NAVIGATION_BACK = 'navigation.back' as const
// Text as const
export const ALIGN_LEFT = 'left' as const
export const ALIGN_CENTER = 'center' as const
export const ALIGN_RIGHT = 'right' as const

// Group Types
export const GROUP_TYPE_GROUP = null
export const GROUP_TYPE_INPUT = 'input'

export const GROUP_TYPES = [
  { label: 'Group', value: GROUP_TYPE_GROUP },
  { label: 'Text Input', value: GROUP_TYPE_INPUT },
] as const

// Event Types
export const events = {
  TAP: 'tap',
  LONG_PRESS: 'longPress',
} as const

export const EVENT_NAMES = {
  [events.TAP]: 'Tap',
  [events.LONG_PRESS]: 'Long Press',
} as const

export const EVENT_OPTIONS = [
  { label: EVENT_NAMES[events.TAP], value: events.TAP },
  { label: EVENT_NAMES[events.LONG_PRESS], value: events.LONG_PRESS },
] as const

export const actionTypes = {
  CREATE_OBJECT: 'createObject',
  UPDATE_OBJECT: 'updateObject',
  DELETE_OBJECT: 'deleteObject',
  CREATE_ASSOCIATION: 'createAssociation',
  DELETE_ASSOCIATION: 'deleteAssociation',
  NAVIGATE: 'navigate',
  AUTHENTICATE: 'authenticate',
  LOGOUT: 'logout',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgotPassword',
  SHARE: 'share',
  SET_INPUT_VALUE: 'setInputValue',
  CALL_API: 'callAPI',
  AUTHENTICATE_API: 'authAPI',
  SIGNUP_API: 'signupAPI',
  PUSH_NOTIFICATION: 'pushNotification',
  NOTIFICATION_PERMISSION: 'notificationPermission',
  EXTERNAL_LINK: 'externalLink',
  CUSTOM: 'custom',
  AUTHENTICATE_EXTERNAL: 'authenticateExternal',
  SIGNUP_EXTERNAL: 'signupExternal',
  LOCATION_PERMISSION: 'locationPermission',
  COLLECTION_API_GET_ALL: 'collectionAPI.getAll',
  COLLECTION_API_GET_ONE: 'collectionAPI.getOne',
  COLLECTION_API_CREATE: 'collectionAPI.create',
  COLLECTION_API_UPDATE: 'collectionAPI.update',
  COLLECTION_API_DELETE: 'collectionAPI.delete',
  EXTERNAL_COLLECTION_CREATE: 'externalCollection.create',
  EXTERNAL_COLLECTION_UPDATE: 'externalCollection.update',
  EXTERNAL_COLLECTION_DELETE: 'externalCollection.delete',
} as const

export const ACTION_NAMES = {
  [actionTypes.CREATE_OBJECT]: 'Create',
  [actionTypes.UPDATE_OBJECT]: 'Update',
  [actionTypes.DELETE_OBJECT]: 'Delete',
  [actionTypes.CREATE_ASSOCIATION]: 'Add To Collection',
  [actionTypes.DELETE_ASSOCIATION]: 'Remove From Collection',
  [actionTypes.NAVIGATE]: 'Link',
  [actionTypes.AUTHENTICATE]: 'Log In',
  [actionTypes.LOGOUT]: 'Log Out',
  [actionTypes.SIGNUP]: 'Sign Up',
  [actionTypes.FORGOT_PASSWORD]: 'Forgot Password',
  [actionTypes.SHARE]: 'Share',
  [actionTypes.SET_INPUT_VALUE]: 'Change Input Value',
  [actionTypes.CALL_API]: 'Call API',
  [actionTypes.AUTHENTICATE_API]: 'Log In',
  [actionTypes.SIGNUP_API]: 'Sign Up',
  [actionTypes.PUSH_NOTIFICATION]: 'Trigger Notification',
  [actionTypes.NOTIFICATION_PERMISSION]: 'Request Notification Permission',
  [actionTypes.EXTERNAL_LINK]: 'External Link',
  [actionTypes.CUSTOM]: 'Custom Action',
  [actionTypes.AUTHENTICATE_EXTERNAL]: 'External Log In',
  [actionTypes.SIGNUP_EXTERNAL]: 'External Sign Up',
  [actionTypes.LOCATION_PERMISSION]: 'Request Location Permission',
} as const

export const authTypes = {
  EMAIL: 'email',
  USERNAME: 'username',
  PHONE_PASSWORD: 'phone/password',
  PHONE_CODE: 'phone/code',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
} as const

export const authTypeOptions = [
  { label: 'Email', value: authTypes.EMAIL, icon: 'text-format' },
  { label: 'Username', value: authTypes.USERNAME, icon: 'text-format' },
] as const

// Data types
export const dataTypes = {
  TEXT: 'text',
  DATE: 'date',
  DATE_ONLY: 'dateOnly',
  LOCATION: 'location',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  LIST: 'list',
  IMAGE: 'image',
  VIDEO: 'video',
  TABLE: 'table',
  COLOR: 'color',
  ICON: 'icon',
  FILE: 'file',
  PASSWORD: 'password',
  OBJECT: 'object',
  FOREIGN_KEY: 'foreignKey',
  ACTION_REF: 'actionRef',
  CONDITION: 'condition',
} as const

export const richObjectDataTypes = [
  dataTypes.IMAGE,
  dataTypes.FILE,
  dataTypes.LOCATION,
] as const

// Control types (for libraries + inspector)
export const controlTypes = {
  MENU: 'menu',
  SLIDER: 'slider',
  SHADOW: 'shadow',
  BORDER: 'border',
} as const

// Field source types
export const SOURCE_TYPE_INPUT = 'input' as const

export const sourceTypes = {
  INPUT: SOURCE_TYPE_INPUT,
  FIELD: 'field',
  COUNT: 'count',
  MIN: 'min',
  MAX: 'max',
  MIN_MAX: 'minMax',
  SUM: 'sum',
  AVERAGE: 'avg',
  DATA: 'data',
  PARAM: 'param',
  LIST_ITEM: 'listItem', // TODO: Remove (replaced by selectors)
  RELATION: 'relation', // TODO: Remove (replaced by selectors)
  ROUTE_PARAM: 'routeParam', // TODO: Remove (replaced by selectors)
  PARENT_BINDING: 'parentBinding', // TODO: Remove (replaced by selectors)
  BELONGS_TO: 'belongsToRelation',
  HAS_MANY: 'hasManyRelation',
  MANY_TO_MANY: 'manyToManyRelation',
  DATETIME: 'dateTime',
  API_ENDPOINT: 'apiEndpoint',
  API_FIELD: 'apiField',
  AUTOSAVE: 'autosave',
  ACTION_ARGUMENT: 'actionArgument',
  CUSTOM_ACTION: 'customAction',
  EXTERNAL_USERS_TOKEN: 'externalUsersToken',
  EXTERNAL_USERS_ID: 'externalUsersId',
  DEVICE_LOCATION: 'deviceLocation',
  CUSTOM_LOCATION: 'customLocation',
  DEVICE_DIMENSIONS: 'deviceDimensions',
} as const

export const sourceTypeNames = {
  [sourceTypes.COUNT]: 'Count',
  [sourceTypes.MIN]: 'Minimum',
  [sourceTypes.MAX]: 'Maximum',
  [sourceTypes.MIN_MAX]: 'Min / Max',
  [sourceTypes.SUM]: 'Sum',
  [sourceTypes.AVERAGE]: 'Average',
  [sourceTypes.ACTION_ARGUMENT]: 'Custom Component Action Argument',
} as const

export const isAggregateType = (type: string) => !!sourceTypeNames[type]

// Data binding types
export const bindingTypes = {
  SET_PROPERTY: 'property',
  SET_TEXT: 'text',
  SET_IMAGE: 'image',
  SET_LOCATION: 'location',
  VISIBILITY: 'visibility',
  LIST: 'list',
  SET_DATE: 'date', // used for date picker
  SET_URI: 'uri', // temporary - replace with SET_PROPERTY
  LIBRARY_PROP: 'libraryProp', // Used as placeholder for library bindings
} as const

export const bindingTypeNames = {
  [bindingTypes.SET_PROPERTY]: 'Set Property',
  [bindingTypes.SET_TEXT]: 'Replace Text',
  [bindingTypes.SET_IMAGE]: 'Replace Image',
  [bindingTypes.SET_LOCATION]: 'Set Location',
  [bindingTypes.VISIBILITY]: 'Show / Hide',
  [bindingTypes.LIST]: 'List Items',
  [bindingTypes.SET_DATE]: 'Set Date',
  [bindingTypes.SET_URI]: 'Set URL',
} as const

// Data formatting types
export const coordinateFormats = {
  DECIMAL: 'decimal',
  DECIMAL_DEGREES: 'decimalDegrees',
  DECIMAL_DEGREES_MINUTES: 'decimalDegreesMinutes',
  DECIMAL_DEGREES_MINUTES_SECONDS: 'decimalDegreesMinutesSeconds',
} as const

export const numberFormats = {
  NONE: 'none',
  COMMAS: 'commas',
  ABBREVIATED: 'abbreviated',
  CURRENCY: 'currency',
  ABBREVIATED_CURRENCY: 'abbreviatedCurrency',
} as const

export const formatters = {
  [dataTypes.DATE]: {
    RELATIVE: 'relativeDate',
    RELATIVE_SHORT: 'relativeShort',
    ABSOLUTE_DATE: 'absoluteDate',
    UNIVERSAL_DATE: 'universalDate',
    ABSOLUTE_DATETIME: 'absoluteDatetime',
    WEEK_DAY_LONG: 'weekDayLong',
    WEEK_DAY_SHORT: 'weekDayShort',
    DAY_NUMBER: 'dayNumber',
    MONTH_NAME_LONG: 'monthNameLong',
    MONTH_NAME_SHORT: 'monthNameShort',
    MONTH_NUMBER: 'monthNumber',
    YEAR_NUMBER_LONG: 'yearNumberLong',
    YEAR_NUMBER_SHORT: 'yearNumberShort',
    TIME: 'time',
    ISO: 'iso',
  },
  [dataTypes.DATE_ONLY]: {
    RELATIVE: 'dateOnlyRelative',
    ABSOLUTE_DATE: 'dateOnlyAbsolute',
    UNIVERSAL_DATE: 'dateOnlyUniversal',
    WEEK_DAY_LONG: 'dateOnlyWeekday',
    WEEK_DAY_SHORT: 'dateOnlyWeekdayShort',
    DAY_NUMBER: 'dateOnlyDayNumber',
    MONTH_NAME_LONG: 'dateOnlyMonthNameLong',
    MONTH_NAME_SHORT: 'dateOnlyMonthNameShort',
    MONTH_NUMBER: 'dateOnlyMonthNumber',
    YEAR_NUMBER_LONG: 'dateOnlyYearNumberLong',
    YEAR_NUMBER_SHORT: 'dateOnlyYearNumberShort',
    ISO: 'iso',
  },
  [dataTypes.NUMBER]: numberFormats,
  [dataTypes.LOCATION]: {
    coordinates: {
      latitude: coordinateFormats,
      longitude: coordinateFormats,
    },
  },
  [dataTypes.FILE]: {
    size: numberFormats,
  },
} as const

// Current year to use in subtitle label
const currentYear = new Date().getFullYear()

export const formatterOptions = {
  [dataTypes.DATE]: [
    {
      label: 'Relative',
      subtitle: '10 days ago',
      value: formatters.date.RELATIVE,
    },
    {
      label: 'Relative (short)',
      subtitle: '10d',
      value: formatters.date.RELATIVE_SHORT,
    },
    {
      label: 'Date (MM/DD/YYYY)',
      subtitle: `7/31/${currentYear}`,
      value: formatters.date.ABSOLUTE_DATE,
    },
    {
      label: 'Date (DD/MM/YYYY)',
      subtitle: `31/7/${currentYear}`,
      value: formatters.date.UNIVERSAL_DATE,
    },
    {
      label: 'Date / Time',
      subtitle: `7/31/${currentYear} at 2:15 PM`,
      value: formatters.date.ABSOLUTE_DATETIME,
    },
    null,
    {
      label: 'Day of Week',
      subtitle: 'Monday',
      value: formatters.date.WEEK_DAY_LONG,
    },
    {
      label: 'Day of Week (short)',
      subtitle: 'Mon',
      value: formatters.date.WEEK_DAY_SHORT,
    },
    {
      label: 'Day Number',
      subtitle: '31',
      value: formatters.date.DAY_NUMBER,
    },
    null,
    {
      label: 'Month Name',
      subtitle: 'January',
      value: formatters.date.MONTH_NAME_LONG,
    },
    {
      label: 'Month Name (short)',
      subtitle: 'Jan',
      value: formatters.date.MONTH_NAME_SHORT,
    },
    {
      label: 'Month Number',
      subtitle: '7',
      value: formatters.date.MONTH_NUMBER,
    },
    null,
    {
      label: 'Year Number',
      subtitle: String(currentYear),
      value: formatters.date.YEAR_NUMBER_LONG,
    },
    {
      label: 'Year Number (short)',
      subtitle: String(currentYear).substring(2),
      value: formatters.date.YEAR_NUMBER_SHORT,
    },
    null,
    { label: 'Time', subtitle: '2:15 PM', value: formatters.date.TIME },
    null,
    {
      label: 'No Formatting',
      subtitle: `${currentYear}-07-31T14:15:00.000Z`,
      value: formatters.date.ISO,
    },
  ],
  [dataTypes.DATE_ONLY]: [
    {
      label: 'Relative',
      subtitle: '10 days ago',
      value: formatters.dateOnly.RELATIVE,
    },
    {
      label: 'Date (MM/DD/YYYY)',
      subtitle: `7/31/${currentYear}`,
      value: formatters.dateOnly.ABSOLUTE_DATE,
    },
    {
      label: 'Date (DD/MM/YYYY)',
      subtitle: `31/7/${currentYear}`,
      value: formatters.dateOnly.UNIVERSAL_DATE,
    },
    null,
    {
      label: 'Day of Week',
      subtitle: 'Monday',
      value: formatters.dateOnly.WEEK_DAY_LONG,
    },
    {
      label: 'Day of Week (short)',
      subtitle: 'Mon',
      value: formatters.dateOnly.WEEK_DAY_SHORT,
    },
    {
      label: 'Day Number',
      subtitle: '31',
      value: formatters.dateOnly.DAY_NUMBER,
    },
    null,
    {
      label: 'Month Name',
      subtitle: 'January',
      value: formatters.dateOnly.MONTH_NAME_LONG,
    },
    {
      label: 'Month Name (short)',
      subtitle: 'Jan',
      value: formatters.dateOnly.MONTH_NAME_SHORT,
    },
    {
      label: 'Month Number',
      subtitle: '7',
      value: formatters.dateOnly.MONTH_NUMBER,
    },
    null,
    {
      label: 'Year Number',
      subtitle: String(currentYear),
      value: formatters.dateOnly.YEAR_NUMBER_LONG,
    },
    {
      label: 'Year Number (short)',
      subtitle: String(currentYear).substring(2),
      value: formatters.dateOnly.YEAR_NUMBER_SHORT,
    },
    null,
    {
      label: 'No Formatting',
      subtitle: `${currentYear}-07-31`,
      value: formatters.date.ISO,
    },
  ],
  [dataTypes.NUMBER]: [
    {
      label: 'Default',
      subtitle: '1,500,000',
      value: formatters.number.COMMAS,
    },
    null,
    { label: 'None', subtitle: '1500000', value: formatters.number.NONE },
    {
      label: 'Abbreviated',
      subtitle: '1.5M',
      value: formatters.number.ABBREVIATED,
    },
    null,
    {
      label: 'Currency',
      subtitle: '$1,500,000.00',
      value: formatters.number.CURRENCY,
    },
    {
      label: 'Currency Abbreviated',
      subtitle: '$1.5M',
      value: formatters.number.ABBREVIATED_CURRENCY,
    },
  ],
  [dataTypes.LOCATION]: {
    coordinates: {
      latitude: [
        {
          label: 'Decimal',
          title: 'Decimal',
          subtitle: '38.658320',
          value: formatters[dataTypes.LOCATION].coordinates.longitude.DECIMAL,
        },
        null,
        {
          label: 'D.D°',
          title: 'Decimal Degrees',
          subtitle: '38.658320° N',
          value:
            formatters[dataTypes.LOCATION].coordinates.latitude.DECIMAL_DEGREES,
        },
        {
          label: "D° M.M'",
          title: 'Degrees, Decimal Minutes',
          subtitle: "38° 39.503706' N",
          value:
            formatters[dataTypes.LOCATION].coordinates.latitude
              .DECIMAL_DEGREES_MINUTES,
        },
        {
          label: 'D° M\' S"',
          title: 'Degrees, Minutes, Seconds',
          subtitle: '38° 39\' 30" N',
          value:
            formatters[dataTypes.LOCATION].coordinates.latitude
              .DECIMAL_DEGREES_MINUTES_SECONDS,
        },
      ],
      longitude: [
        {
          label: 'Decimal',
          title: 'Decimal',
          subtitle: '-90.591630',
          value: formatters[dataTypes.LOCATION].coordinates.longitude.DECIMAL,
        },
        null,
        {
          label: 'D.D°',
          title: 'Decimal Degrees',
          subtitle: '-90.591630° W',
          value:
            formatters[dataTypes.LOCATION].coordinates.longitude
              .DECIMAL_DEGREES,
        },
        {
          label: "D° M.M'",
          title: 'Degrees, Decimal Minutes',
          subtitle: "-90° 35.503518' W",
          value:
            formatters[dataTypes.LOCATION].coordinates.longitude
              .DECIMAL_DEGREES_MINUTES,
        },
        {
          label: 'D° M\' S"',
          title: 'Degrees, Minutes, Seconds',
          subtitle: '-90° 35\' 30" W',
          value:
            formatters[dataTypes.LOCATION].coordinates.longitude
              .DECIMAL_DEGREES_MINUTES_SECONDS,
        },
      ],
    },
  },
  [dataTypes.FILE]: {
    size: [
      {
        label: 'Default',
        subtitle: '1,500,000',
        value: formatters.number.COMMAS,
      },
      null,
      { label: 'None', subtitle: '1500000', value: formatters.number.NONE },
    ],
  },
} as const

// Sort directions
export const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
} as const

export const sortDirectionOptions = [
  { label: 'Ascending', value: sortDirections.ASC },
  { label: 'Descending', value: sortDirections.DESC },
] as const

// Object positioning
export const positioning = {
  FIXED_TOP: 'fixedTop',
  FIXED_BOTTOM: 'fixedBottom',
  FIXED: 'fixed',
  DEFAULT: null,
} as const

export const positioningOptions = [
  { label: 'Top', value: positioning.FIXED_TOP },
  { label: 'Bottom', value: positioning.FIXED_BOTTOM },
  { label: 'None', value: positioning.DEFAULT },
] as const

// Border styles
export const borderStyle = {
  NONE: 'none',
  SOLID: 'solid',
  DOTTED: 'dotted',
  DASHED: 'dashed',
} as const

// Alias
export const borderStyles = borderStyle

export const borderStyleOptions = [
  { label: 'None', value: borderStyle.NONE },
  { label: '▬▬▬▬▬▬▬▬▬', value: borderStyle.SOLID },
  { label: '▪▪▪▪▪▪▪▪▪', value: borderStyle.DOTTED },
  { label: '▬ ▬ ▬ ▬ ▬', value: borderStyle.DASHED },
] as const

export const borderTypeOptions = borderStyleOptions.slice(1)

// Background styles
export const backgroundStyle = {
  NONE: 'none',
  COLOR: 'color',
  IMAGE: 'image',
} as const

// Alias
export const backgroundStyles = backgroundStyle

export const backgroundStyleOptions = [
  { label: 'None', value: backgroundStyle.NONE },
  { label: 'Color', value: backgroundStyle.COLOR },
  { label: 'Image', value: backgroundStyle.IMAGE },
] as const

export const borderPosition = {
  INSIDE: 'inside',
  CENTER: 'center',
  OUTSIDE: 'outside',
} as const

export const borderPositionOptions = [
  { label: 'Inside', value: borderPosition.INSIDE },
  { label: 'Center', value: borderPosition.CENTER },
  { label: 'Outside', value: borderPosition.OUTSIDE },
] as const

// apto-database auth field names
export const fields = {
  USERNAME: 'username',
  EMAIL: 'email',
  PASSWORD: 'password',
  FULL_NAME: 'full_name',
  TEMPORARY_PASSWORD: 'temporary_password',
  TEMPORARY_PASSWORD_EXPIRES_AT: 'temporary_password_expires_at',
} as const

// Single Sign-On ID field names
export const ssoFields = {
  USER_ID: 'user_id',
  PROVIDER: 'provider',
  ID_TOKEN: 'id_token',
  ACCESS_TOKEN: 'access_token',
  OAUTH_ID: 'oauth_id',
} as const

// Text Inputs
export const inputTypes = {
  DEFAULT: 'default',
  LOWERCASE: 'lowercase',
  PASSWORD: 'password',
  EMAIL: 'email',
  NUMBER: 'number',
} as const

export const inputTypeOptions = [
  { label: 'Normal', value: inputTypes.DEFAULT },
  { label: 'Lowercase', value: inputTypes.LOWERCASE },
  { label: 'Password', value: inputTypes.PASSWORD },
  { label: 'Email', value: inputTypes.EMAIL },
  { label: 'Number', value: inputTypes.NUMBER },
] as const

// Comparators

export const comparators = {
  EQUAL: '==',
  NOT_EQUAL: '!=',
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_OR_EQUAL: '>=',
  LESS_THAN_OR_EQUAL: '<=',
  BETWEEN: 'between',
  DISTANCE_LESS_THAN: 'distanceLessThan',
  DISTANCE_GREATER_THAN: 'distanceGreaterThan',
  EMPTY: 'empty',
  NOT_EMPTY: 'notEmpty',
  CONTAINS: 'contains', // Used for string fuzzy search
  LIST_CONTAINS: 'listContains',
  LIST_NOT_CONTAINS: 'listNotContains',
  TRUE: 'true',
  FALSE: 'false',
} as const

export const comparatorLabels = {
  [comparators.EQUAL]: 'Is equal to',
  [comparators.NOT_EQUAL]: 'Is not equal to',
  [comparators.GREATER_THAN]: 'Is greater than',
  [comparators.LESS_THAN]: 'Is less than',
  [comparators.GREATER_THAN_OR_EQUAL]: 'Is greater than or equal',
  [comparators.LESS_THAN_OR_EQUAL]: 'Is less than or equal',
  [comparators.EMPTY]: 'Is empty',
  [comparators.NOT_EMPTY]: 'Is not empty',
  [comparators.BETWEEN]: 'Is between',
  [comparators.DISTANCE_LESS_THAN]: 'Is within',
  [comparators.DISTANCE_GREATER_THAN]: 'Is not within',
  [comparators.CONTAINS]: 'Contains',
  [comparators.LIST_CONTAINS]: 'Contains',
  [comparators.LIST_NOT_CONTAINS]: 'Does not contain',
  [comparators.TRUE]: 'Is true',
  [comparators.FALSE]: 'Is false',
} as const

export const comparatorDistanceUnits = {
  KILOMETERS: 'kilometers',
  MILES: 'miles',
} as const

export const comparatorDistanceUnitLabels = {
  [comparatorDistanceUnits.MILES]: 'Miles of',
  [comparatorDistanceUnits.KILOMETERS]: 'Kilometers of',
} as const

// Device Breakpoints

export const DeviceType = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
} as const

export const DeviceBreakpoint = {
  MOBILE_BREAKPOINT: 719,
  TABLET_BREAKPOINT: 1023,
} as const

export const DeviceWidth = {
  MOBILE_MIN_WIDTH: 320,
  MOBILE_DEFAULT_WIDTH: 390,
  TABLET_DEFAULT_WIDTH: 834,
  DESKTOP_DEFAULT_WIDTH: 1512,
  DESKTOP_MAX_WIDTH: 2560,
} as const

/**
 * @deprecated Use DeviceBreakpoint instead
 */
export const visibility = DeviceBreakpoint

// Responsive layout options

export const resizingOptions = {
  SCALES_WITH_PARENT: 'SCALES_WITH_PARENT',
  FIXED: 'FIXED',
  VARIABLE_HEIGHT: 'VARIABLE_HEIGHT',
  FULL_WIDTH: 'FULL_WIDTH',
} as const

export const responsivePositioningOptions = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  LEFT_AND_RIGHT: 'LEFT_AND_RIGHT',
  CENTER: 'CENTER',
  CUSTOM: 'CUSTOM',
  FIXED_ON_SCROLL: 'FIXED_ON_SCROLL',
  AS_ROW: 'AS_ROW',
} as const

// Status Bar

export const statusBarStyles = {
  DARK_CONTENT: 'dark',
  LIGHT_CONTENT: 'light',
  HIDDEN: 'hidden',
} as const

export const statusBarStyleOptions = [
  { label: 'Auto', value: null },
  { label: 'Black Text', value: statusBarStyles.DARK_CONTENT },
  { label: 'White Text', value: statusBarStyles.LIGHT_CONTENT },
  { label: 'Hidden', value: statusBarStyles.HIDDEN },
] as const

export const imageResize = {
  CONTAIN: 'contain',
  COVER: 'cover',
} as const

export const imageResizeOptions = [
  { label: 'Contain', value: imageResize.CONTAIN },
  { label: 'Cover', value: imageResize.COVER },
] as const

export const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
} as const

export const endpointTypes = {
  LIST: 'list',
  DETAIL: 'detail',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  CUSTOM: 'custom',
} as const

export const endpointTypeNames = {
  [endpointTypes.LIST]: 'Get All',
  [endpointTypes.DETAIL]: 'Get One',
  [endpointTypes.CREATE]: 'Create',
  [endpointTypes.UPDATE]: 'Update',
  [endpointTypes.DELETE]: 'Delete',
  [endpointTypes.CUSTOM]: 'Custom',
} as const

export const defaultMethods = {
  [endpointTypes.LIST]: httpMethods.GET,
  [endpointTypes.DETAIL]: httpMethods.GET,
  [endpointTypes.CREATE]: httpMethods.POST,
  [endpointTypes.UPDATE]: httpMethods.PUT,
  [endpointTypes.DELETE]: httpMethods.DELETE,
} as const

export const restEndpointTypes = [
  endpointTypes.LIST,
  endpointTypes.DETAIL,
  endpointTypes.CREATE,
  endpointTypes.UPDATE,
  endpointTypes.DELETE,
] as const

export const listTypes = {
  DEFAULT: 'default',
  GRID: 'grid',
  HORIZONTAL: 'horizontal',
} as const

export const keyboardBehaviors = {
  DEFAULT: 'default',
  AVOID: 'avoid',
} as const

export const imageTypes = {
  INTERNAL: 'internal',
  URL: 'url',
  UPLOADED: 'uploaded',
} as const

export const locationTypes = {
  DEVICE: 'device',
  DATABASE: 'database',
  CUSTOM: 'custom',
  INPUT: 'input',
} as const

export const fileTypes = {
  INTERNAL: 'internal',
  URL: 'url',
  UPLOADED: 'uploaded',
} as const

// Configuration

export const DEBOUNCE_TIME = 500

export const validators = {
  name: /(?!.*http|https|ftp|www)(^[a-zA-Z\xC0-\uFFFF+\s0-9'-]+$)/,
  email: /^[a-zA-Z0-9\_\-\.+]+\@[a-zA-Z0-9\_\-\.]+$/,
  password: /^[a-zA-Z0-9!@#$%^&*_+\-=\[\]\|]{8,}$/,
} as const

export const PASSWORD_LENGTH_MAX = 1000

export const API_PROVIDERS = {
  airtable: {
    shouldAppend: false,
  },
  noProvider: {
    shouldAppend: true,
  },
} as const

export const VERSION_FIELD_MAX_LENGTH = 255
export const MAX_VERSIONS = 10

export const AUTOSAVED_VERSION = {
  name: 'Autosave design version',
  notes: '',
  creator: {
    name: 'Adalo with love',
    email: '',
  },
} as const

// Table component
export type FormatTypes =
  | 'singleLine'
  | 'multiLine'
  | 'noMax'
  | 'fullAddress'
  | 'addressElements.address1'
  | 'addressElements.name'
  | 'addressElements.city'
  | 'addressElements.state'
  | 'addressElements.country'
  | 'addressElements.postalCode'
  | 'coordinates.latitude'
  | 'coordinates.longitude'

export enum ColumnWidths {
  narrow = 'narrow',
  standard = 'standard',
  wide = 'wide',
}

export type ColumnWidth = keyof typeof ColumnWidths

export const ColumnStyleWidth = {
  [ColumnWidths.narrow]: 90,
  [ColumnWidths.standard]: 180,
  [ColumnWidths.wide]: 360,
} as const

export enum ColumnAlignments {
  left = 'left',
  center = 'center',
  right = 'right',
}

export type ColumnAlignment = keyof typeof ColumnAlignments

export const columnTypeDefaultAlignment: Record<string, ColumnAlignment> = {
  text: ColumnAlignments.left,
  number: ColumnAlignments.right,
  boolean: ColumnAlignments.center,
  image: ColumnAlignments.center,
  file: ColumnAlignments.left,
  location: ColumnAlignments.left,
  date: ColumnAlignments.left,
  dateTime: ColumnAlignments.left,
} as const

export enum ImageRatios {
  square = 'square',
  circle = 'circle',
  landscape = 'landscape',
  portrait = 'portrait',
}

export type ImageRatio = keyof typeof ImageRatios

export const ImageRatioStyle = {
  [ImageRatios.square]: { width: 60, height: 60 },
  [ImageRatios.circle]: { width: 60, height: 60 },
  [ImageRatios.landscape]: { width: 60, height: 40 },
  [ImageRatios.portrait]: { width: 60, height: 90 },
} as const

export type FormatKeys = {
  type?: string
  currency?: string
  abbreviatedCurrency?: string
  textLength?: FormatTypes
  columnWidth?: ColumnWidth
  columnAlignment?: ColumnAlignment
  imageRatio?: ImageRatio
  required?: boolean
  requiredText?: string
  shouldFreezeFirstColumn?: boolean
  locationFormat?: FormatTypes
  prefix?: string
  suffix?: string
  prefixSingular?: string
  suffixSingular?: string
}

export type Comparator =
  | typeof comparators[keyof typeof comparators]
  | undefined

export type DataType = typeof dataTypes[keyof typeof dataTypes]

export type BindingType =
  | 'property'
  | 'text'
  | 'image'
  | 'visibility'
  | 'list'
  | 'date'
  | 'uri'
  | 'libraryProp'

export type SourceType =
  | 'input'
  | 'field'
  | 'count'
  | 'min'
  | 'max'
  | 'minMax'
  | 'sum'
  | 'avg'
  | 'data'
  | 'param'
  | 'listItem'
  | 'relation'
  | 'routeParam'
  | 'parentBinding'
  | 'belongsToRelation'
  | 'hasManyRelation'
  | 'manyToManyRelation'
  | 'dateTime'
  | 'apiEndpoint'
  | 'apiField'
  | 'autosave'
  | 'actionArgument'
  | 'customAction'
  | 'externalUsersToken'
  | 'externalUsersId'

export interface BindingSource {
  dataType: DataType
  datasourceId: string
  tableId: string
  type: SourceType
  sort?: null
  source?: BindingSource | null
  fieldId?: string
}

// Common interfaces
export interface Binding {
  bindingType: BindingType
  childBindings?: []
  comparator?: Comparator
  comparison?: string | Binding
  comparison2?: string | Binding
  comparatorOptions?: {
    unit?: typeof comparatorDistanceUnits[keyof typeof comparatorDistanceUnits]
    radius?: number | Binding
  }
  id?: string
  format?: FormatKeys
  options?: {
    affectLayout?: boolean
    filter?: {
      fieldId: string
      comparator?: Comparator
      comparison?: string | Binding
      comparison2?: string | Binding
      comparatorOptions?: {
        unit?: typeof comparatorDistanceUnits[keyof typeof comparatorDistanceUnits]
        radius?: number | Binding
      }
    }[]
  }
  siblingBindings?: []
  source: BindingSource
  type: 'binding' | string
  label: string
}

export interface Location {
  placeId: string
  fullAddress: string
  addressElements: {
    address1: string
    address2: string
    city: string
    state: string
    region: string
    postalCode: string
  }
  coordinates: {
    latitude: number
    longitude: number
  }
}
