import { useMemo } from 'react'

import ToggleButton from 'components/Shared/Forms/ToggleButton'

import { EditorObject } from 'utils/responsiveTypes'

import ColorDropdown from './ColorDropdown'
import TextControl from './TextControl'
import GenericInspectRow from './GenericRow'

type ShadowControlProps = {
  object: {
    shadow?: {
      color: string
      x: number
      y: number
      size: number
      enabled: boolean
    }
  }
  onChange: (arg: EditorObject['attributes']) => void
}

export const ShadowControl: React.FC<ShadowControlProps> = ({
  object,
  onChange,
}) => {
  const isEnabled = useMemo(() => object.shadow?.enabled === true, [object])

  return (
    <>
      <GenericInspectRow title="Shadow" className="toggle-button-block">
        <div className="toggle-button-block-box">
          {isEnabled ? 'On' : 'Off'}
          <ToggleButton
            value={isEnabled}
            onChange={() => {
              onChange({
                shadow: {
                  ...object.shadow,
                  enabled: !isEnabled,
                },
              })
            }}
          />
        </div>
      </GenericInspectRow>
      {isEnabled ? (
        <GenericInspectRow
          title=""
          className="default-shadow-control"
          key="shadowControl"
        >
          <ColorDropdown
            label="Shadow Color"
            value={object.shadow?.color ?? '#000000'}
            name="shadowColor"
            onChange={({ shadowColor }: { shadowColor: string }) =>
              onChange({
                shadow: {
                  ...object.shadow,
                  color: shadowColor,
                },
              })
            }
          />
          <TextControl
            gray
            type="number"
            name="shadowX"
            value={object.shadow?.x ?? 0}
            onChange={({ shadowX }: { shadowX: number }) =>
              onChange({
                shadow: {
                  ...object.shadow,
                  x: shadowX,
                },
              })
            }
            title="X"
          />
          <TextControl
            gray
            type="number"
            name="shadowY"
            value={object.shadow?.y ?? 0}
            onChange={({ shadowY }: { shadowY: number }) =>
              onChange({
                shadow: {
                  ...object.shadow,
                  y: shadowY,
                },
              })
            }
            title="Y"
          />
          <TextControl
            gray
            type="number"
            name="size"
            value={object.shadow?.size ?? 0}
            onChange={({ size }: { size: number }) =>
              onChange({
                shadow: {
                  ...object.shadow,
                  size,
                },
              })
            }
            title="Size"
          />
        </GenericInspectRow>
      ) : null}
    </>
  )
}
