import io from 'socket.io-client'
import { adaloBackendAxios } from 'utils/io/http/axios'
import { toast, cssTransition } from 'react-toastify'

import Button from 'components/Shared/Button'

import { setData } from 'ducks/editor/objects'

import {
  getApp,
  loadApp,
  loadAppsList,
  loadOrgAppsList,
  loadTeamAppsList,
  loadTemplatesList,
  loadTemplateBranding,
  loadAppDatasourceRelationships,
  templateIdIsBlankType,
  startAppScreenEditSave,
  endAppScreenEditSave,
} from 'ducks/apps'
import { getFeatureFlag } from 'ducks/featureFlags'

import {
  loadData,
  displayError,
  loadAssociationData,
  deleteData,
  bulkDeleteData,
  loadSearch,
} from 'ducks/datasources'

import {
  setCurrentUser,
  setCurrentUserToken,
  setAuthVisible,
} from 'ducks/users/index.ts'
import {
  loadScreenTemplates,
  loadScreenTemplateCategories,
  screenTemplateCreated,
  screenTemplateUpdated,
} from 'ducks/editor/screenTemplates'
import { loadScreenAppBuilds } from 'ducks/apps/builds'

import { socketConnected, socketDisconnected } from 'ducks/sockets'
import { uploadProgress, uploadError } from 'ducks/editor/sketchUpload'
import { TWO_FACTOR_MODAL, showModal } from 'ducks/editor/modals'

import { getAuthToken } from 'utils/auth'
import { ALL_RELATIONSHIPS_BUILDER } from 'utils/includes'
import { defaultBranding } from 'utils/colors'
import { isMobileTouchDevice } from 'utils/browsers'

import { socket } from 'utils/io/socket'
import { getLimits as dbAssistantGetLimits } from 'utils/io/dbAssistant'

import history from '../../history'
import { getPrototypeAppId } from './helpers'

import './toast.scss'

// define store
let reduxStore = null

const saveQueue = {}

socket.on('reconnect_attempt', () => {
  socket.io.opts.query = {
    sessionToken: getAuthToken(),
  }
})

export const unsafeGetStore = () => reduxStore

export const connectSocket = store => {
  const token = getAuthToken()

  reduxStore = store

  if (token) {
    store.dispatch(setCurrentUserToken(token))
  } else {
    store.dispatch(setAuthVisible())
  }

  socket.on('connect', () => {
    store.dispatch(socketConnected())
  })

  socket.on('disconnect', () => {
    store.dispatch(socketDisconnected())
  })

  socket.on('connect_error', () => {
    store.dispatch(socketDisconnected())
  })

  socket.on('tokenRefresh', ({ sessionToken }) => {
    store.dispatch(setCurrentUserToken(sessionToken))
  })

  socket.on('app', async result => {
    if (!result) return null

    const state = store.getState()
    const { appId } = state.editor.objects.present

    // Fetch the app body
    const { appBodyUrl } = result
    let appBody = {}
    let buildSettings = {}
    if (appBodyUrl) {
      const promisedAppBody = adaloBackendAxios.get(appBodyUrl)
      const promisedBuildSettings = adaloBackendAxios.get(
        `/apps/${appId}/settings/build`
      )

      try {
        const [appBodyResult, buildSettingsResult] = await Promise.all([
          promisedAppBody,
          promisedBuildSettings,
        ])

        const { data: fetchedAppBody } = appBodyResult
        appBody = fetchedAppBody

        const { data: fetchedBuildSettings } = buildSettingsResult
        buildSettings = fetchedBuildSettings
      } catch (e) {
        console.error('Error fetching app body', e)

        return null
      }
    }

    // Merge fetched app body into result
    result = {
      ...appBody,
      ...result,
      ...buildSettings,
      loaded: true,
    }

    if (appId && appId === result.id) {
      store.dispatch(setData(appId, result.components, result))
    }

    if (result.associatedApps) {
      result.associatedApps.forEach(app => {
        if (!(app.id in state.apps.apps)) {
          requestAppAttributes(app.id, ['id', 'name', 'bodyIsExternal'])
        }
      })
    }

    const showMobileBlocker = isMobileTouchDevice()

    if (result.created) {
      if (result.onboarding) {
        if (showMobileBlocker) {
          history.push(`/apps/${result.id}/mobile-blocker`)
        } else {
          history.push(`/apps/${result.id}/screens`)
        }
      } else {
        history.push(`/apps/${result.id}`)
      }
    }

    const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')

    const mobileOnly =
      hasNewMobileOnlyApp && result?.webSettings?.layoutMode === 'mobile'

    store.dispatch(loadApp(result, mobileOnly))

    socket.emit('requestAppDatasourceRelationships', { appId })
  })

  socket.on('appAttributes', async result => {
    if (!result) return

    const state = store.getState()

    const { appBodyUrl } = result

    if (appBodyUrl) {
      let appBodyResult
      try {
        appBodyResult = await adaloBackendAxios.get(appBodyUrl)
      } catch (error) {
        console.error('Error fetching app body', error)

        return
      }

      const { data: fetchedAppBody } = appBodyResult

      const appBody = fetchedAppBody

      result = {
        ...appBody,
        ...result,
      }
    }

    if (result.associatedApps) {
      result.associatedApps.forEach(app => {
        if (!(app.id in state.apps.apps)) {
          requestAppAttributes(app.id, ['id', 'name', 'bodyIsExternal'])
        }
      })
    }

    store.dispatch(loadApp(result))
  })

  socket.on('appNotFound', () => {
    history.push('/')
  })

  socket.on('appsList', (apps, lastApp) => {
    store.dispatch(loadAppsList(apps, lastApp))
  })

  socket.on('orgAppsList', (apps, lastApp, target) => {
    store.dispatch(loadOrgAppsList(apps))
  })

  socket.on('teamAppsList', apps => {
    store.dispatch(loadTeamAppsList(apps))
  })

  socket.on('templatesList', (templateList, primaryPlatform) => {
    store.dispatch(loadTemplatesList(templateList, primaryPlatform))
  })

  socket.on('templateBranding', (templateId, branding) => {
    store.dispatch(loadTemplateBranding(templateId, branding))
  })

  socket.on('appDatasourceRelationships', ({ appId, relationships }) => {
    store.dispatch(loadAppDatasourceRelationships(appId, relationships))
  })

  socket.on('debugBuildDetails', payload => {
    console.log(`Got debugBuildDetails message:`)

    if (payload.packagerCommand) {
      const packagerCommand = payload?.packagerCommand
      delete payload.packagerCommand
      console.log(packagerCommand?.replace(/\\"/g, '"'))
    } else {
      console.warn(
        `Missing debugBuildDetails message payload packager command`,
        payload
      )
    }
  })

  socket.on('screenTemplatesList', (platform, list) => {
    const state = store.getState()

    const hasNewMobileOnlyApp = getFeatureFlag(state, 'hasNewMobileOnlyApp')
    const hasNewAddMenuWithSections = getFeatureFlag(
      state,
      'hasNewAddMenuWithSections'
    )

    const { appId } = state.editor.objects.present

    const app = getApp(state, appId)

    const mobileOnly =
      hasNewMobileOnlyApp && app && app?.webSettings?.layoutMode === 'mobile'

    const showResponsive = hasNewAddMenuWithSections

    store.dispatch(
      loadScreenTemplates(platform, list, mobileOnly, showResponsive)
    )
  })

  socket.on('screenTemplateCategoriesList', (platform, list) => {
    store.dispatch(loadScreenTemplateCategories(platform, list))
  })

  socket.on('screenTemplateCreated', (platform, template) => {
    store.dispatch(screenTemplateCreated(platform, template))
  })

  socket.on('screenTemplateUpdated', (platform, template) => {
    store.dispatch(screenTemplateUpdated(platform, template))
  })

  socket.on('appBuilds', (appId, target, list) => {
    store.dispatch(loadScreenAppBuilds(appId, target, list))
  })

  socket.on('data', ({ tableId, result, error }) => {
    if (error) return store.dispatch(displayError(error))
    store.dispatch(loadData(tableId, result))
    store.dispatch(displayError(null))
  })

  socket.on('dataSearch', ({ tableId, result, error }) => {
    if (error) return store.dispatch(displayError(error))
    store.dispatch(loadSearch(tableId, result))
    store.dispatch(displayError(null))
  })

  socket.on('associationData', ({ fieldId, result }) => {
    store.dispatch(loadAssociationData(fieldId, result))
  })

  socket.on('dataDeleted', ({ tableId, id }) => {
    store.dispatch(deleteData(tableId, id))
  })

  socket.on('bulkDataDeleted', ({ tableId, blockedList, idList }) => {
    store.dispatch(bulkDeleteData(tableId, blockedList, idList))
  })

  socket.on('userProfile', user => {
    store.dispatch(setCurrentUser(user))
  })

  socket.on('unauthorized', () => {
    console.warn('Unauthorized operation.')
    // TODO: bring back once we fix the cloning page
    // window.location.assign(
    //   `${window.location.protocol}//${window.location.host}`
    // )
  })

  socket.on('unauthenticated', () => {
    store.dispatch(setAuthVisible())
  })

  socket.on('uploadProgress', progressObj => {
    store.dispatch(uploadProgress(progressObj))
  })

  socket.on('uploadError', () => {
    window.alert(
      'We were unable to upload your Sketch document. Please make sure it was produced with a recent version of Sketch (45+) and is less than 100MB in size.'
    )

    store.dispatch(uploadError())
  })

  socket.on('requestApple2FA', async callback => {
    try {
      const result = await store.dispatch(showModal(TWO_FACTOR_MODAL))
      callback({ code: result.value })
    } catch (err) {
      callback({ error: 'Cancelled' })
    }
  })

  socket.on('toast_error', ({ toast: toastMessage }) => {
    /**
     * @type {{
     *   title: string,
     *   message: string,
     *   suggestRefresh: boolean
     * }}
     */
    const { title, message, suggestRefresh } = toastMessage

    toast(
      <>
        <div>
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
        <div>
          {suggestRefresh === true && (
            <Button yellow outlined onClick={() => window.location.reload()}>
              Reload
            </Button>
          )}
        </div>
      </>,
      {
        position: 'bottom-right',
        className: 'generic-error__toast',
        bodyClassName: 'generic-error__toast-body',
        hideProgressBar: true,
        autoClose: 10000,
        transition: cssTransition({
          enter: 'animate__animated animate__fadeInUp',
          exit: 'animate__animated animate__fadeOutDown',
          collapse: false,
        }),
      }
    )
  })
}

// Change Queue
window.setInterval(() => {
  forceSaveComponents()
}, 30000)

const SAVE_TIMEOUT_MILLISECONDS = 30000

const waitForEvent = eventName => {
  return new Promise((resolve, reject) => {
    const onEvent = ({ success, err }) => {
      clearTimeout(timeoutId)

      if (success) {
        resolve()
      } else {
        reject(new Error(`Save failed: ${err}`))
      }
    }

    const clearEverything = () => {
      clearTimeout(timeoutId)
      socket.off(eventName, onEvent)
    }

    const onTimeOut = () => {
      clearEverything()
      reject(new Error('TIMED OUT'))
    }

    const timeoutId = setTimeout(onTimeOut, SAVE_TIMEOUT_MILLISECONDS)

    socket.once(eventName, onEvent)
  })
}

export const forceSaveComponents = (onSaveFinish = undefined) => {
  const store = unsafeGetStore()

  /** Promise<any>[] */
  const pendingSaves = []

  /** string[] */
  const pendingSaveAppIds = Object.keys(saveQueue)

  for (const appId of Object.keys(saveQueue)) {
    const changes = saveQueue[appId]

    // Clear out previous values
    delete saveQueue[appId]

    const { components, libraryGlobals } = changes

    if (!components && !libraryGlobals) {
      continue
    }

    store.dispatch(startAppScreenEditSave(appId))

    pendingSaves.push(waitForEvent(`savedComponents:${appId}`))

    socket.emit('saveComponent', {
      appId,
      components,
      libraryGlobals,
    })
  }

  /**
   *
   * @param {string[]} appIds
   */
  const markAppsAsSaved = appIds => {
    for (const appId of appIds) {
      store.dispatch(endAppScreenEditSave(appId))
    }
  }

  if (onSaveFinish && typeof onSaveFinish === 'function') {
    Promise.all(pendingSaves) //
      .then(() => onSaveFinish())
      .catch(err => onSaveFinish(err))
      .finally(() => markAppsAsSaved(pendingSaveAppIds))
  } else {
    // Nobody cares about the result so ignore errors (probably a timeout which is irrelevant when there's no callback)
    Promise.all(pendingSaves)
      .catch(() => {})
      .finally(() => markAppsAsSaved(pendingSaveAppIds))
  }
}

const addSavesToQueue = (appId, components, libraryGlobals) => {
  saveQueue[appId] = {
    components: {
      ...saveQueue[appId]?.components,
      ...components,
    },
    libraryGlobals: {
      ...saveQueue[appId]?.libraryGlobals,
      ...libraryGlobals,
    },
  }
}

export const requestAll = () => {
  socket.emit('requestAll')
}

export const requestAllOrgApps = orgId => {
  socket.emit('requestAllOrgApps', { orgId })
}

/**
 * Request All Apps for a Team regardless if a maker is an App User
 */
export const requestAllTeamApps = orgId => {
  socket.emit('requestAllTeamApps', { orgId })
}

export const requestApp = appId => {
  socket.emit('requestApp', { appId })
}

export const requestAppAttributes = (appId, attributes) => {
  socket.emit('requestAppAttributes', { appId, attributes })
}

export const createApp = (opts, callback) => {
  const {
    name,
    datasourceType,
    datasourceAppId,
    organizationId,
    templateId,
    prototypeAppId,
    copyApp,
    cloneAppWithData,
    copyDatabase,
    type,
    builtWithFreelancer,
    magicLayout: optsMagicLayout = false,
    webSettings,
  } = opts

  const { primaryPlatform } = opts

  let magicLayout = optsMagicLayout

  if (primaryPlatform === 'responsive') {
    magicLayout = true
  }

  let fonts = {
    body: {
      family: 'default',
    },
    heading: {
      family: 'Libre Baskerville',
      variants: ['200', '300', 'regular', '500', '600', '700'],
      category: 'serif',
    },
  }

  if ((copyApp || cloneAppWithData || copyDatabase) && opts.branding.fonts) {
    fonts = opts.branding.fonts
  }

  const branding = {
    ...defaultBranding,
    ...opts.branding,
    fonts,
  }

  const ioOpts = {
    name,
    datasourceType,
    datasourceAppId,
    primaryPlatform,
    organizationId,
    branding,
    copyApp,
    cloneAppWithData,
    magicLayout,
    type,
    builtWithFreelancer,
    webSettings,
  }

  if (templateId && !templateIdIsBlankType(templateId)) {
    ioOpts.templateId = templateId
  } else {
    if (prototypeAppId) {
      ioOpts.prototypeAppId = prototypeAppId
    } else {
      ioOpts.prototypeAppId = getPrototypeAppId(
        datasourceType,
        primaryPlatform,
        templateId
      )
    }
  }

  return new Promise((resolve, reject) => {
    socket.emit('createApp', ioOpts, errorCreatingApp => {
      if (callback) {
        callback(errorCreatingApp)
      }

      resolve()
    })
  })
}

export const createOnboardingApp = (opts, callback) => {
  const {
    name,
    baseAppId,
    organizationId,
    primaryPlatform,
    appCategory,
    webSettings,
  } = opts

  let fonts = {
    body: {
      family: 'default',
    },
    heading: {
      family: 'Libre Baskerville',
      variants: ['200', '300', 'regular', '500', '600', '700'],
      category: 'serif',
    },
  }

  if (opts.branding.fonts) {
    fonts = opts.branding.fonts
  }

  const branding = { ...defaultBranding, ...opts.branding, fonts }

  const ioOpts = {
    baseAppId,
    name,
    organizationId,
    appCategory,
    primaryPlatform,
    branding,
    webSettings,
  }

  return new Promise((resolve, reject) => {
    socket.emit('createOnboardingApp', ioOpts, errorCreatingApp => {
      if (callback) {
        callback(errorCreatingApp)
      }

      resolve()
    })
  })
}

export const updateApp = (appId, data) => {
  if (!appId) return
  socket.emit('updateApp', { appId, data })
}

export const bulkUpdateApps = (organizationId, apps) => {
  socket.emit('bulkUpdateApps', { organizationId, apps })
}

export const deleteApp = async appId => {
  return new Promise((resolve, reject) => {
    socket.emit('deleteApp', { appId }, ({ success }) => {
      if (success) {
        resolve()
      } else {
        reject()
      }
    })
  })
}

export const saveComponent = (appId, componentId, data) => {
  socket.emit('saveComponent', { appId, componentId, data })
}

export const saveComponents = (appId, components, libraryGlobals) => {
  addSavesToQueue(appId, components, libraryGlobals)
}

export const deleteComponent = (appId, componentId) => {
  if (saveQueue[appId]?.components[componentId]) {
    delete saveQueue[appId].components[componentId]
  }

  socket.emit('deleteComponent', { appId, componentId })
}

export const saveLibraryGlobals = (appId, libraryGlobals) => {
  socket.emit('saveLibraryGlobals', appId, libraryGlobals)
}

export const saveDatasource = (appId, datasourceId, data) => {
  socket.emit('saveDatasource', { appId, datasourceId, data }, () => {
    socket.emit('requestAppDatasourceRelationships', { appId })
    dbAssistantGetLimits(appId)
  })
}

export const uploadSketchFile = (appId, file, fileType, callback) => {
  console.log('FILE TYPE:', fileType)

  socket.emit('uploadSketchFile', { appId, file, fileType }, callback)
}

export const setParams = opts => {
  socket.emit('setParams', opts)
}

// ARGS = {
//   appId,
//   datasourceId,
//   tableId,
//   [id],
//   [tableConfig],
// }

/**
 * Requests data
 * @param {*} args
 * { appId, datasourceId, tableId, [id], [tableConfig], queryParams }
 */
export const requestData = async (args = {}, sendCallback = false) => {
  let { tableConfig, skipDBAssistantInitialization = false, ...opts } = args

  let queryParams = {}
  if (opts.queryParams) queryParams = opts.queryParams
  if (tableConfig) {
    queryParams.include = tableConfig ? ALL_RELATIONSHIPS_BUILDER : ''
  }

  opts = {
    ...opts,
    queryParams,
  }

  return new Promise(resolve => {
    const callback = sendCallback ? resolve : undefined

    socket.emit('requestData', opts, callback)

    if (opts.appId && skipDBAssistantInitialization === false) {
      dbAssistantGetLimits(opts.appId)
    }

    if (!sendCallback) {
      resolve()
    }
  })
}

// Used to create a Table data object
export const saveDataObject = (appId, datasourceId, tableId, id, data) => {
  socket.emit('saveDataObject', { appId, datasourceId, tableId, id, data })
}

export const deleteDataObject = (appId, datasourceId, tableId, id) => {
  socket.emit('deleteDataObject', { appId, datasourceId, tableId, id })
}

export const bulkDeleteDataObjects = (
  appId,
  datasourceId,
  tableId,
  blockedList,
  idList,
  queryParams
) => {
  socket.emit(
    'bulkDeleteDataObjects',
    {
      appId,
      datasourceId,
      tableId,
      blockedList,
      idList,
      queryParams,
    },
    () => {
      dbAssistantGetLimits(appId)
    }
  )
}

// opts = { appId, datasourceId, tableId, id, tableId2, id2, fieldId }
export const addAssociation = opts => {
  socket.emit('addAssociation', opts)
}

// opts = { appId, datasourceId, tableId, id, tableId2, id2, fieldId }
export const removeAssociation = opts => {
  socket.emit('removeAssociation', opts)
}

export const authenticate = (data, callback) => {
  return new Promise((resolve, reject) => {
    socket.emit('authenticate', data, ({ success, sessionToken }) => {
      if (success) {
        resolve(sessionToken)
      } else {
        reject(new Error('Invalid email or password'))
      }
    })
  })
}

export const magicAuthenticate = (data, callback) => {
  return new Promise((resolve, reject) => {
    socket.emit('magicAuthenticate', data, ({ success, sessionToken }) => {
      if (success) {
        resolve(sessionToken)
      } else {
        reject(new Error('Invalid email or password'))
      }
    })
  })
}

export const passwordRecovery = (data, callback) => {
  socket.emit('passwordRecovery', data, callback)
}

export const updatePassword = (data, callback) => {
  socket.emit('updatePassword', data, callback)
}

export const adminUpdatePassword = data => {
  return new Promise((resolve, reject) => {
    socket.emit('adminUpdatePassword', data, ({ success, errorMessage }) => {
      if (success) {
        resolve()
      } else {
        reject(new Error(errorMessage))
      }
    })
  })
}

export const signup = (data, callback) => {
  socket.emit('signup', data, callback)
}

export const uploadImage = (appId, file, filename) =>
  new Promise((resolve, reject) => {
    socket.emit('uploadImage', { appId, file, filename }, result => {
      if (result.success) {
        resolve(result.filename)
      } else {
        reject(new Error('Unable to upload asset'))
      }
    })
  })

export const updateAccount = data => {
  return new Promise((resolve, reject) => {
    socket.emit('updateAccount', data, result => {
      if (result && result.success) {
        resolve(result)
      } else {
        reject(result)
      }
    })
  })
}

export const requestTemplates = primaryPlatform => {
  socket.emit('requestAllTemplates', primaryPlatform)
}

export const requestTemplateBranding = templateId => {
  socket.emit('requestTemplateBranding', templateId)
}

export const requestScreenTemplates = (platform = 'mobile') => {
  socket.emit('requestScreenTemplates', platform)
}

export const requestScreenTemplateCategories = (platform = 'mobile') => {
  socket.emit('requestScreenTemplateCategories', platform)
}

export const createOrUpdateScreenTemplate = (appId, componentId, values) => {
  return new Promise((resolve, reject) => {
    const callback = (error, id) => {
      if (error) {
        reject(error)
      } else {
        resolve(id)
      }
    }

    socket.emit(
      'createOrUpdateScreenTemplate',
      appId,
      componentId,
      values,
      callback
    )

    socket.emit('createScreenTemplate', appId, componentId, values, callback)
  })
}

export const cloneScreenTemplatesToApp = (templateId, appId, screenName) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'cloneScreenTemplatesToApp',
      templateId,
      appId,
      screenName,
      (error, id) => {
        if (error) {
          reject(error)
        } else {
          resolve(id)
        }
      }
    )
  })
}

export const cloneFeatureTemplateToApp = (
  templateId,
  appId,
  datasourceId,
  options
) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'cloneFeatureTemplateToApp',
      templateId,
      appId,
      datasourceId,
      options,
      (_, result) => {
        if (result?.error) reject(result.error)
        else resolve(result)
      }
    )
  })
}

export const requestAppBuilds = (appId, target, opts = {}) => {
  socket.emit('requestAppBuilds', appId, target, opts)
}

export const requestStartAppBuild = (
  appId,
  target,
  version,
  skipBuildEnqueue
) => {
  forceSaveComponents()

  return new Promise((resolve, reject) => {
    socket.emit(
      'startAppBuild',
      appId,
      target,
      version,
      skipBuildEnqueue,
      (error, build) => {
        if (skipBuildEnqueue) {
          alert(`
                You skipped build enqueueing
                Check the console for the build payload
          `)
        }
        if (error) {
          reject(error)
        } else {
          resolve(build)
        }
      }
    )
  })
}

export const verifyIOSCredentials = async appId =>
  new Promise((resolve, reject) => {
    socket.emit('verifyIOSCredentials', appId, (error, message) => {
      if (error) {
        reject(new Error(message))
      } else {
        resolve()
      }
    })
  })

export const joinAppRoom = appId => {
  if (!appId) {
    console.warn(`Attempted to join app room with no appId`)
  } else {
    socket.emit('joinAppRoom', { appId }, error => {
      if (error) {
        console.warn(`Failed to join app room: ${error}`)
      }
    })
  }
}

// database socket
const databaseWSURL = process.env.REACT_APP_DATABASE_URL

export const openDatabaseSocket = () =>
  io(databaseWSURL, { transports: ['websocket'] })

export * from 'utils/io/versions'
export { baseURL, baseCDNURL } from 'utils/io/constants'
