import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import QS from 'qs'

import { setCurrentUserToken } from 'ducks/users/index.ts'
import { authenticate } from 'utils/io'

import LoginForm from './AuthV2/LoginForm.tsx'
import AuthPage from './AuthV2/AuthPage.tsx'

import './Auth.scss'

const Login = props => {
  const { location, history, setCurrentUserToken } = props

  const redirect = useMemo(() => {
    const { redirect } = QS.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    return redirect
  }, [location])

  const handleLogin = async data => {
    try {
      const sessionToken = await authenticate(data)

      setCurrentUserToken(sessionToken)
      history.push(redirect || '/')
    } catch (err) {
      throw new SubmissionError({ _error: 'Invalid email or password' })
    }
  }

  return (
    <AuthPage className="login-page" version={2}>
      <LoginForm onSubmit={handleLogin} redirect={redirect} />
    </AuthPage>
  )
}

export default connect(null, { setCurrentUserToken })(Login)
