import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import QS from 'qs'

import { setCurrentUserToken } from 'ducks/users/index.ts'

import { parseJWT } from 'utils/auth'
import { signup } from 'utils/io'
import { getReferral } from 'utils/rewardful'
import { trackSignupEvent } from 'utils/facebookPixelEvents'

import AuthPage from './AuthV2/AuthPage.tsx'
import SignupForm from './AuthV2/SignupForm.tsx'

const Signup = props => {
  const { location, children, history, setCurrentUserToken } = props

  const { initialValues, error, inviteToken, redirect } = useMemo(() => {
    const { location } = props
    const queryParams = QS.parse(location.search, { ignoreQueryPrefix: true })
    const { token, redirect } = queryParams

    let initialValues = {}
    let error = null

    if (token) {
      const tokenBody = parseJWT(token)

      if (tokenBody) {
        initialValues = { email: tokenBody.email }
      } else {
        error = 'Your invitation has expired. Invitations expire after 7 days.'
      }
    }

    return { initialValues, error, inviteToken: token, redirect }
  }, [location])

  const handleSubmit = values =>
    new Promise((resolve, reject) => {
      const rewardfulToken = getReferral()

      // for hubspot
      const pageUri = window.location.href
      const pageName = document.title

      const cookies = Object.fromEntries(
        document.cookie.split('; ').map(c => {
          const [key, ...v] = c.split('=')

          return [key, v.join('=')]
        })
      )

      const hubspotutk = cookies?.hubspotutk

      const data = {
        ...values,
        inviteToken,
        rewardfulToken,
        hubspotutk,
        pageUri,
        pageName,
      }

      signup(data, ({ success, errors, sessionToken, newOrg }) => {
        if (!success) {
          return reject(new SubmissionError(errors))
        }

        setCurrentUserToken(sessionToken)
        trackSignupEvent()

        const nextPage = 'onboarding'

        let redirectPage = `/${nextPage}`

        if (inviteToken) {
          redirectPage = `/${nextPage}?token=${inviteToken}`
        }

        if (redirectPage) {
          if (!String(redirectPage).startsWith('/')) {
            redirectPage = `/${redirectPage}`
          }

          history.push(redirectPage)
        } else if (newOrg) {
          const newOrgNextPage = '/onboarding'
          history.push(newOrgNextPage)
        } else {
          history.push('/')
        }

        resolve()
      })
    })

  const isInvite = location.pathname === '/accept-invite'

  return (
    <AuthPage name="signup-page" error={error} version={2}>
      <SignupForm
        isInvite={isInvite}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        redirect={redirect}
      />
      {children}
    </AuthPage>
  )
}

export default connect(null, { setCurrentUserToken })(Signup)
