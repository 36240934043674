// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./default-icon.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-icon {\n  width: 60px;\n  height: 60px;\n  border-radius: 13px;\n  background-color: #fff;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);\n}\n\n.app-icon-small {\n  width: 30px;\n  height: 30px;\n  border-radius: 7px;\n}\n\n.app-icon-medium {\n  width: 40px;\n  height: 40px;\n  border-radius: 9px;\n}\n\n.app-icon-dark-background {\n  box-shadow: none;\n}\n\n.app-icon-empty {\n  background: #d1d1d1 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 60%;\n  box-shadow: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/Shared/AppIcon/AppIcon.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,8CAA8C;AAChD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,4EAA8D;EAC9D,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".app-icon {\n  width: 60px;\n  height: 60px;\n  border-radius: 13px;\n  background-color: #fff;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);\n}\n\n.app-icon-small {\n  width: 30px;\n  height: 30px;\n  border-radius: 7px;\n}\n\n.app-icon-medium {\n  width: 40px;\n  height: 40px;\n  border-radius: 9px;\n}\n\n.app-icon-dark-background {\n  box-shadow: none;\n}\n\n.app-icon-empty {\n  background: #d1d1d1 url('./default-icon.svg') no-repeat center;\n  background-size: 60%;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
