// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./cursors/pen-plus.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "./cursors/pen-plus@2x.png";
import ___CSS_LOADER_URL_IMPORT_2___ from "./cursors/pen-close-path.png";
import ___CSS_LOADER_URL_IMPORT_3___ from "./cursors/pen-close-path@2x.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cursor-crosshair {\n  cursor: crosshair;\n}\n\n.cursor-text {\n  cursor: text;\n}\n\n.cursor-move {\n  cursor: move;\n}\n\n.cursor-pen-plus {\n  cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 7 1, default;\n  cursor: -webkit-image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 1x, url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 2x) 7 1, default;\n}\n\n.cursor-pen-close-path {\n  cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") 7 1, default;\n  cursor: -webkit-image-set(url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") 1x, url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") 2x) 7 1, default;\n}\n", "",{"version":3,"sources":["webpack://src/utils/tools/tools.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,4DAAkD;EAClD,8HAA6G;AAC/G;;AAEA;EACE,4DAAwD;EACxD,8HAAyH;AAC3H","sourcesContent":[".cursor-crosshair {\n  cursor: crosshair;\n}\n\n.cursor-text {\n  cursor: text;\n}\n\n.cursor-move {\n  cursor: move;\n}\n\n.cursor-pen-plus {\n  cursor: url('./cursors/pen-plus.png') 7 1, default;\n  cursor: -webkit-image-set(url('./cursors/pen-plus.png') 1x, url('./cursors/pen-plus@2x.png') 2x) 7 1, default;\n}\n\n.cursor-pen-close-path {\n  cursor: url('./cursors/pen-close-path.png') 7 1, default;\n  cursor: -webkit-image-set(url('./cursors/pen-close-path.png') 1x, url('./cursors/pen-close-path@2x.png') 2x) 7 1, default;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
