import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { sanitize } from 'dompurify'
import { decode } from 'html-entities'

const DEFAULT_VALUE = [['']]
const LEFT_ARROW = 37
const RIGHT_ARROW = 39

export default class EntityTextarea extends PureComponent {
  state = {
    selectionStart: 0,
    selectionEnd: 0,
    currentBlock: 0,
    currentSegment: 0,
    focussedChip: null,
  }

  segments = {}
  entities = {}
  _doubleClickBlock = null
  _doubleClickStart = null

  notifyChange = blocks => {
    let { onChange } = this.props

    if (onChange) {
      onChange(blocks)
    }
  }

  getBlocks = () => {
    const { value } = this.props

    return value || DEFAULT_VALUE
  }

  sanitizeText = dirty => {
    const { sanitization: constant } = this.props

    switch (constant) {
      case sanitization.IGNORE:
        return dirty

      case sanitization.HTML:
        return decode(
          sanitize(dirty, {
            KEEP_CONTENT: true,
            USE_PROFILES: { html: true },
            WHOLE_DOCUMENT: true,
          })
        )

      default:
        return decode(sanitize(dirty, { KEEP_CONTENT: true }))
    }
  }

  handleInput = (currentBlock, segmentIndex, isOnBlur) => e => {
    let blocks = this.getBlocks()
    let block = blocks[currentBlock].slice()

    let text = e.currentTarget.innerText
    if (isOnBlur) {
      text = this.sanitizeText(text)
      const stuckInLoop = block[segmentIndex] === text
      if (stuckInLoop) {
        return
      }
    }

    const selection = window.getSelection()
    const selectionStart = text.length - selection.anchorOffset
    const currentSegment = block.length - segmentIndex - 1

    block = block.slice()
    blocks = blocks.slice()

    block[segmentIndex] = text
    blocks[currentBlock] = block

    this.notifyChange(blocks)

    this.setState({
      selectionStart,
      selectionEnd: selectionStart,
      currentBlock,
      currentSegment,
    })
  }

  handleKeyDown = (currentBlock, currentSegment) => e => {
    // Return
    if (e.which === 13) {
      e.preventDefault()
      let blocks = this.getBlocks()
      blocks = blocks.slice()

      let selectionStart = this.getSelectionStart()
      let selectionEnd = this.getSelectionEnd()

      let block = blocks[currentBlock]
      let segment = block[currentSegment]

      let beforeBlock = blocks[currentBlock].slice(0, currentSegment)
      beforeBlock.push(segment.slice(0, selectionStart))

      let afterBlock = [segment.slice(selectionEnd)]
      afterBlock = afterBlock.concat(
        blocks[currentBlock].slice(currentSegment + 1)
      )

      blocks.splice(currentBlock, 1, beforeBlock, afterBlock)

      this.notifyChange(blocks)
      let newBlock = currentBlock + 1
      let newBlockContent = blocks[newBlock]
      currentSegment = newBlockContent.length - 1
      selectionStart = newBlockContent[0].length

      this.setState({
        selectionStart,
        currentSegment,
        currentBlock: newBlock,
        selectionEnd: selectionStart,
      })
    }

    // Delete
    if (e.which === 8) {
      let selection = window.getSelection()
      e.stopPropagation()

      if (selection.anchorOffset === 0 && selection.isCollapsed) {
        e.preventDefault()

        if (currentSegment === 0 && currentBlock > 0) {
          // Join with previous block
          let blocks = this.getBlocks()
          let prevBlock = currentBlock - 1
          let prevSegment = blocks[prevBlock].length - 1
          let prevSegmentLength = (blocks[prevBlock][prevSegment] || '').length
          let currentBlockContent = blocks[currentBlock]
          blocks = blocks.slice()
          blocks.splice(currentBlock, 1)
          blocks[prevBlock] = blocks[prevBlock].concat(
            currentBlockContent.slice(1)
          )
          blocks[prevBlock][prevSegment] += currentBlockContent[0]
          let selectionStart = prevSegmentLength

          let tidy = this.tidyBlock(
            blocks[prevBlock],
            prevSegment,
            selectionStart
          )

          blocks[prevBlock] = tidy.block

          this.notifyChange(blocks)

          this.focusText(0, 0)

          let segmentObj = blocks[prevBlock][tidy.currentSegment]

          this.setState({
            selectionStart: segmentObj.length - tidy.selectionStart,
            selectionEnd: segmentObj.length - tidy.selectionStart,
            currentBlock: prevBlock,
            currentSegment: tidy.block.length - 1 - tidy.currentSegment,
          })
        } else if (currentSegment > 0) {
          let blocks = this.getBlocks()
          let prevSegment = currentSegment - 1
          blocks = blocks.slice()
          let block = blocks[currentBlock].slice()
          block.splice(prevSegment, 1)

          let tidy = this.tidyBlock(block, prevSegment, 0)

          blocks[currentBlock] = tidy.block

          this.notifyChange(blocks)

          this.focusText(currentBlock, tidy.currentSegment, tidy.selectionStart)

          let segmentObj = tidy.block[tidy.currentSegment]

          this.setState({
            currentBlock,
            currentSegment: tidy.block.length - 1 - tidy.currentSegment,
            selectionStart: segmentObj.length - tidy.selectionStart,
            selectionEnd: segmentObj.length - tidy.selectionEnd,
          })
        }
      }
    } else if (e.which === LEFT_ARROW) {
      let position = this.getPosition()
      let blocks = this.getBlocks()
      let block = blocks[position.currentBlock]

      if (!block) {
        return
      }

      if (position.selectionStart !== position.selectionEnd) {
        return
      }
      if (position.selectionStart !== 0) {
        return
      }

      e.preventDefault()

      if (position.currentSegment === 0 && position.currentBlock > 0) {
        this.setState({
          currentBlock: position.currentBlock - 1,
          currentSegment: 0,
          selectionStart: 0,
          selectionEnd: 0,
        })
      } else if (position.currentSegment !== 0) {
        this.setState({
          currentSegment: block.length - 1 - position.currentSegment + 2,
          selectionStart: 0,
          selectionEnd: 0,
        })
      }
    } else if (e.which === RIGHT_ARROW) {
      let position = this.getPosition()
      let blocks = this.getBlocks()
      let block = blocks[position.currentBlock]

      if (!block) {
        return
      }

      let segment = block[position.currentSegment]

      if (position.selectionStart !== position.selectionEnd) {
        return
      }
      if (position.selectionStart !== segment.length) {
        return
      }

      e.preventDefault()

      if (
        position.currentSegment === block.length - 1 &&
        position.currentBlock < blocks.length - 1
      ) {
        this.setState({
          currentBlock: position.currentBlock + 1,
          currentSegment: blocks[position.currentBlock + 1].length - 1,
          selectionStart: blocks[position.currentBlock + 1][0].length,
          selectionEnd: blocks[position.currentBlock + 1][0].length,
        })
      } else if (position.currentSegment !== block.length - 1) {
        this.setState({
          currentSegment: block.length - 1 - position.currentSegment - 2,
          selectionStart: block[position.currentSegment + 2].length,
          selectionEnd: block[position.currentSegment + 2].length,
        })
      }
    }
  }

  handleKeyUp = e => {
    //this.setState(state => ({ iteration: state.iteration + 1 }))
  }

  handleDoubleClickBlock = (block, segment) => {
    let blocks = this.getBlocks()
    let blockValue = blocks[block]
    let segmentIndex = blockValue && blockValue.length - segment - 1
    let segmentValue = blockValue && blockValue[segmentIndex]

    if (typeof segmentValue !== 'string') {
      return
    }

    this.setState({
      selectionStart: segmentValue.length,
      selectionEnd: 0,
      currentBlock: block,
      currentSegment: segment,
    })
  }

  handleClickBlock = block => e => {
    e.preventDefault()
    e.stopPropagation()
    let blocks = this.getBlocks()
    let timestamp = +new Date()

    let position = e.currentTarget.getBoundingClientRect()

    let currentSegment = 0
    let selectionStart = 0

    if (e.clientX <= position.left + 20) {
      currentSegment = blocks[block].length - 1
      selectionStart = (blocks[block][0] || '').length
    }

    if (
      block === this._doubleClickBlock &&
      timestamp - this._doubleClickStart < 600
    ) {
      return this.handleDoubleClickBlock(block, currentSegment)
    }

    this._doubleClickBlock = block
    this._doubleClickStart = timestamp

    let segmentIndex = blocks[block].length - 1 - currentSegment
    let key = `${block}-${segmentIndex}`
    let segment = this.segments[key]

    if (segment) {
      this.focusText(block, segmentIndex)

      window.setTimeout(() => {
        this.setState({
          currentSegment,
          selectionStart,
          selectionEnd: selectionStart,
          currentBlock: block,
          focussedChip: null,
        })
      }, 0)
    }
  }

  handleMouseUpBlock = e => {
    e.preventDefault()
  }

  handleClickInput = e => {
    e.stopPropagation()
  }

  getActive = () => {
    let element = document.activeElement

    return Object.values(this.segments).includes(element)
  }

  getPosition = () => {
    let element = document.activeElement
    let currentSegment = null
    let currentBlock = null

    let blocks = this.getBlocks()

    blocks.forEach((block, i) => {
      block.forEach((segment, j) => {
        let key = this.getKey(i, j)
        let segmentEl = this.segments[key]

        if (segmentEl === element) {
          currentBlock = i
          currentSegment = j
        }
      })
    })

    let selectionStart = this.getSelectionStart()
    let selectionEnd = this.getSelectionEnd()

    if (currentSegment === null || currentBlock === null) {
      currentBlock = blocks.length - 1
      currentSegment = blocks[currentBlock].length - 1
      selectionStart = blocks[currentBlock][currentSegment].length
      selectionEnd = selectionStart
    }

    return {
      currentBlock,
      currentSegment,
      selectionStart,
      selectionEnd,
    }
  }

  addEntity = (entity, defaultSelected = false) => {
    let {
      selectionStart,
      selectionEnd,
      currentBlock,
      currentSegment,
    } = this.getPosition()

    let blocks = this.getBlocks()

    if (!entity || typeof entity !== 'object') {
      throw new Error('entity must be an object')
    }

    let newBlock = entity
    blocks = blocks.slice()
    let block = blocks[currentBlock].slice()
    let segment = block[currentSegment]
    let before = segment.slice(0, selectionStart)
    let after = segment.slice(selectionEnd)

    blocks[currentBlock] = blocks[currentBlock].slice()
    blocks[currentBlock].splice(currentSegment, 1, before, newBlock, after)
    let segmentObj = blocks[currentBlock][currentSegment + 2]
    let newSelection = segmentObj && segmentObj.length

    this.notifyChange(blocks)

    if (defaultSelected) {
      this.focusChip(currentBlock, currentSegment + 1)
    } else {
      this.setState({
        selectionStart: newSelection,
        selectionEnd: newSelection,
      })
    }
  }

  handleClickChip = (currentBlock, currentSegment) => e => {
    e.stopPropagation()
    e.currentTarget.focus()
  }

  focusChip = (block, segment) => {
    let key = this.getKey(block, segment)

    window.setTimeout(() => {
      let el = this.entities[key]

      if (el) {
        el.focus()
      }
    }, 0)
  }

  handleFocusChip = (currentBlock, currentSegment) => e => {
    this.setState({
      focussedChip: [currentBlock, currentSegment],
    })

    let selection = window.getSelection()
    selection.removeAllRanges()
  }

  handleBlurChip = (currentBlock, currentSegment) => e => {
    this.setState({ focussedChip: null })
  }

  deleteSegment = (currentBlock, currentSegment) => {
    let blocks = this.getBlocks()

    let block = blocks[currentBlock]
    block = block.slice()
    block.splice(currentSegment, 1)

    if (block.length === 0) {
      block.push('')
    }

    blocks = blocks.slice()
    currentSegment =
      block.length > currentSegment ? currentSegment : block.length - 1

    let tidy = this.tidyBlock(block, currentSegment, 0)

    blocks[currentBlock] = tidy.block

    let el = this.segments[this.getKey(currentBlock, currentSegment - 1)]
    el.focus()

    this.notifyChange(blocks)
    let segmentObj = tidy.block[tidy.currentSegment]
    let selectionStart = segmentObj.length - tidy.selectionStart

    this.setState({
      currentBlock,
      currentSegment: tidy.block.length - 1 - tidy.currentSegment,
      selectionStart,
      selectionEnd: selectionStart,
      focussedChip: null,
    })
  }

  updateSegment = (currentBlock, currentSegment) => newEntity => {
    let blocks = this.getBlocks().slice()
    let block = blocks[currentBlock]

    if (!block) {
      return
    }

    block = block.slice()
    block.splice(currentSegment, 1, newEntity)
    blocks.splice(currentBlock, 1, block)

    this.notifyChange(blocks)
  }

  handleGlobalKeyDown = e => {
    if (e.which === 8) {
      if (e.target.className !== 'entity-textarea-chip') {
        return
      }

      let { focussedChip } = this.state

      if (focussedChip) {
        // Delete that segment
        let [currentBlock, currentSegment] = focussedChip

        window.setTimeout(() => {
          this.deleteSegment(currentBlock, currentSegment)
        }, 0)
      }
    }
  }

  // Selection change event
  handleSelectionChange = e => {
    let selection = window.getSelection()
    let selectionStart = this.getSelectionStart()
    let selectionEnd = this.getSelectionEnd()

    if (
      !selection.anchorNode ||
      !this._wrapper ||
      !this._wrapper.contains(selection.anchorNode)
    ) {
      return
    }

    let parent =
      selection.anchorNode && selection.anchorNode.tagName
        ? selection.anchorNode
        : selection.anchorNode.parentNode

    let currentBlock = parent.getAttribute('data-block')
    let segmentIndex = parent.getAttribute('data-segment')

    if (currentBlock === null || segmentIndex === null) {
      return
    }

    let blocks = this.getBlocks()
    let currentSegment = blocks[currentBlock].length - segmentIndex - 1
    let segmentLength = blocks[currentBlock][segmentIndex].length
    selectionStart = segmentLength - selectionStart
    selectionEnd = segmentLength - selectionEnd

    let {
      selectionStart: stateSelectionStart,
      selectionEnd: stateSelectionEnd,
      currentBlock: stateCurrentBlock,
      currentSegment: stateCurrentSegment,
    } = this.state

    if (
      selectionStart !== stateSelectionStart ||
      selectionEnd !== stateSelectionEnd ||
      currentBlock !== stateCurrentBlock ||
      currentSegment !== stateCurrentSegment
    ) {
      this.setState({
        selectionStart,
        selectionEnd,
        currentBlock,
        currentSegment,
      })
    }
  }

  getSelectionStart = () => {
    let selection = window.getSelection()

    return Math.min(selection.anchorOffset, selection.focusOffset)
  }

  getSelectionEnd = () => {
    let selection = window.getSelection()

    return Math.max(selection.anchorOffset, selection.focusOffset)
  }

  tidyBlock = (block, currentSegment, selectionStart) => {
    block = block.slice()

    for (let i = block.length - 2; i >= 0; i -= 1) {
      if (typeof block[i] === 'string' && typeof block[i + 1] === 'string') {
        if (currentSegment > i + 1) {
          currentSegment -= 1
        } else if (currentSegment === i + 1) {
          currentSegment -= 1
          selectionStart += block[i].length
        }

        let newSegment = `${block[i]}${block[i + 1]}`
        block.splice(i, 2, newSegment)
      }
    }

    return {
      block,
      currentSegment,
      selectionStart,
    }
  }

  //////////////////////////////////////////////////////
  //
  //  Render-loop stuff
  //
  //////////////////////////////////////////////////////

  isEmpty = () => {
    let blocks = this.getBlocks()

    return (
      blocks.length === 0 ||
      (blocks.length === 1 && blocks[0].length === 1 && !blocks[0][0])
    )
  }

  focusText = (blockIndex, segmentIndex, selectionStart) => {
    this.focusTextSub(blockIndex, segmentIndex, selectionStart)

    window.setTimeout(() => {
      this.focusTextSub(blockIndex, segmentIndex, selectionStart)
    }, 0)
  }

  focusTextSub = (blockIndex, segmentIndex, selectionStart = 0) => {
    let selection = window.getSelection()
    selection.removeAllRanges()
    let el = this.segments[this.getKey(blockIndex, segmentIndex)]

    if (!el) {
      return
    }

    selection.setBaseAndExtent(el, 0, el, 0)

    for (let i = 0; i < selectionStart; i += 1) {
      selection.modify('move', 'forward', 'character')
    }
  }

  isChromeBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isChrome = userAgent.indexOf('chrome') > -1
    return isChrome
  }

  setSelection = (force = false) => {
    let {
      currentBlock,
      currentSegment,
      selectionStart,
      selectionEnd,
      focussedChip,
    } = this.state

    let blocks = this.getBlocks()

    let blockObj = blocks[currentBlock] || []
    currentSegment = blockObj.length - currentSegment - 1
    let segmentObj = blockObj[currentSegment] || ''
    selectionStart = segmentObj.length - selectionStart
    selectionEnd = segmentObj.length - selectionEnd

    blocks.forEach((block, i) => {
      block.forEach((segment, j) => {
        if (typeof segment === 'string') {
          let key = this.getKey(i, j)
          let ref = this.segments[key]

          if (ref) {
            if (ref.innerText !== segment) {
              ref.innerText = segment
            }
          }
        }
      })
    })

    if (focussedChip || (!this.getActive() && !force)) {
      return
    }

    let key = this.getKey(currentBlock, currentSegment)
    let el = this.segments[key]

    if (!el) {
      return
    }
    let selection = window.getSelection()
    selection.setBaseAndExtent(el, 0, el, 0)

    //el.focus()
    let currentPosition = selection.anchorOffset

    if (currentPosition > selectionStart) {
      if (this.isChromeBrowser()) {
        selection.modify('move', 'backward', 'documentboundary')
      }

      currentPosition = 0
    }

    for (let i = currentPosition; i < selectionStart; i += 1) {
      selection.modify('move', 'forward', 'character')
    }

    if (selectionEnd > selectionStart) {
      for (let i = selectionStart; i < selectionEnd; i += 1) {
        selection.modify('extend', 'forward', 'character')
      }
    }
  }

  componentDidUpdate() {
    if (this._activeAtRender) {
      this.setSelection(true)
    }
  }

  segmentRef = (block, segment) => el => {
    this.segments[this.getKey(block, segment)] = el
  }

  entityRef = (block, segment) => el => {
    this.entities[this.getKey(block, segment)] = el
  }

  wrapperRef = wrapper => {
    this._wrapper = wrapper
  }

  getKey = (block, segment) => {
    return `${block}-${segment}`
  }

  getOpts = (block, segment) => ({
    onDelete: () => this.deleteSegment(block, segment),
    onUpdate: this.updateSegment(block, segment),
  })

  render() {
    let { className, renderEntity, placeholder } = this.props
    let blocks = this.getBlocks()
    let empty = this.isEmpty()
    this._activeAtRender = this.getActive()

    return (
      <React.Fragment>
        <div
          className={classNames('entity-textarea', className)}
          ref={this.wrapperRef}
        >
          {empty ? (
            <div className="entity-textarea-placeholder-wrapper">
              <div className="entity-textarea-placeholder">{placeholder}</div>
            </div>
          ) : null}
          {blocks.map((block, i) => (
            <div
              key={i}
              className="entity-textarea-block"
              onMouseDown={this.handleClickBlock(i)}
              onMouseUp={this.handleMouseUpBlock}
            >
              {block.map((itm, j) =>
                typeof itm === 'string' ? (
                  <span
                    contentEditable
                    suppressContentEditableWarning
                    key={j}
                    className="entity-textarea-segment"
                    onInput={this.handleInput(i, j)}
                    onBlur={this.handleInput(i, j, true)}
                    onKeyDown={this.handleKeyDown(i, j)}
                    onKeyUp={this.handleKeyUp(i, j)}
                    onMouseDown={this.handleClickInput}
                    ref={this.segmentRef(i, j)}
                    data-block={i}
                    data-segment={j}
                  >
                    {itm}
                  </span>
                ) : (
                  <span
                    key={j}
                    tabIndex={-1}
                    className="entity-textarea-chip"
                    onMouseDown={this.handleClickChip(i, j)}
                    onFocus={this.handleFocusChip(i, j)}
                    onBlur={this.handleBlurChip(i, j)}
                    ref={this.entityRef(i, j)}
                  >
                    {renderEntity(itm, this.getOpts(i, j))}
                  </span>
                )
              )}
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export const sanitization = {
  IGNORE: 'IGNORE',
  HTML: 'HTML',
}
